@import "./base.less";
@import "./markdown.less";
@import "./buttons.less";

// @font-face {
//   font-family: "OpenDyslexic";
//   src: url('font/opendyslexic/eot/FiraSans-Regular.eot');
//   src: url('font/opendyslexic/eot/FiraSans-Regular.eot') format('embedded-opentype'),
//        url('font/opendyslexic/woff2/FiraSans-Regular.woff2') format('woff2'),
//        url('font/opendyslexic/woff/FiraSans-Regular.woff') format('woff'),
//        url('font/opendyslexic/woff2/FiraSans-Regular.ttf') format('truetype');
// }

html, body {
  background: #242222;
  color: #eee;
}

main {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

// Format CSS des questions.
.question {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue", "Arial",  sans-serif;

  .q-title {
    margin: 0;
    padding: 1rem 0 1rem 3rem;
    background: #343333;
  }

  .q-title::before {
    content: url('../img/q.svg');
    position: absolute;
    top: 14px;
    left: 12px;
    width: 2rem;
  }

  .q-instruction {
    color: #333;
    padding: 0rem 1.5rem 2rem 1.5rem;
    background: #FFF;
    font-size: 18px;
    text-align: justify;

    .audio {
      position: absolute;
      top: 74px;
      right: 1.5rem;
      width: 42px;
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;
      transition: all 150ms;

      &:hover {
        width: 48px;
        background: #0C506B40;
      }
      &:active {
        background: #0C506B80;
      }
    }

    h3 {
      color: #0C506B;
    }

    strong {
      color: #301F71;
    }
  }

  .q-answers {
    margin: 0;
    padding: 0;
    background: #343333;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 0 1rem;
      font-size: 20px;

      li {
        list-style-type: none;
      }
    }

    .qroc-answer {
      padding: 1rem 1rem 0.2rem 1rem;

      input {
        margin: 0 12px;
      }
    }
  }

  .q-footer {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
    background: #343333;
  }

  .drawer {
    position: relative;
    width: 100%;
    height: 380px;
  }
}

