html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  color: #444;
  -webkit-text-size-adjust: 100%;
  background: #f8f8f8;
  margin: 0;
  padding: 0;
  font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif !important;
}

.segmented_container {
  max-width: 500px;
  margin: 0 auto;
}

.slider_container {
  width: 90%;
  max-width: 380px;
  margin: 0 auto;
  padding: 25px 0;
}

.long_slider .slider_container {
  max-width: 600px;
}

.slider_left_gutter, .slider_right_gutter, .slider_knob {
  background: #777;
}

.slider_left_gutter, .slider_right_gutter {
  height: 4px;
  border-radius: 2px;
  position: absolute;
  top: 18px;
}

.slider_knob {
  width: 40px;
  height: 40px;
  cursor: ew-resize;
  touch-action: none;
  border-radius: 20px;
  position: absolute;
}

.slider_left_gutter, .slider_right_gutter {
  top: -2px;
}

.slider_right_gutter {
  opacity: .2;
}

.slider_knob {
  top: -20px;
  left: -20px;
}

.post_title {
  padding-top: .4em;
  font-size: 2.4em;
}

.post_title a {
  color: #535353;
  font-weight: 700;
  text-decoration: none;
}

.post_title a:visited {
  color: #535353;
}

.post_title a:hover {
  color: #777;
}

.sqrt {
  margin-left: 12px;
  position: relative;
}

.sqrt:after {
  content: "";
  width: 25px;
  height: 23px;
  position: absolute;
  top: 0;
  left: -12px;
}

.drawer_container {
  width: 75%;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  margin: 2.3em auto 2em;
  padding: 0;
  position: relative;
}

.drawer_container:after {
  content: "";
  padding-top: 66%;
  display: block;
}

.full_width {
  max-width: 100%;
}

.ratio_20:after {
  padding-top: 20%;
}

.ratio_25:after {
  padding-top: 25%;
}

.ratio_30:after {
  padding-top: 30%;
}

.ratio_40:after {
  padding-top: 40%;
}

.ratio_50:after {
  padding-top: 50%;
}

.ratio_60:after {
  padding-top: 60%;
}

.ratio_70:after {
  padding-top: 70%;
}

.ratio_80:after {
  padding-top: 80%;
}

.ratio_90:after {
  padding-top: 90%;
}

.ratio_100:after {
  padding-top: 100%;
}

.ratio_110:after {
  padding-top: 110%;
}

.ratio_120:after {
  padding-top: 120%;
}

.ratio_130:after {
  padding-top: 130%;
}

.hidden {
  display: none !important;
}

.nobreak {
  white-space: nowrap;
}

.click_word {
  display: initial;
}

.tap_word {
  display: none;
}

.rounded_bg {
  background: #eee;
  border-radius: .2em;
  margin: 0 .05em;
  padding: -.2em .3em;
  position: relative;
}

.dotted {
  background-image: linear-gradient(to right, #444 50%, #4440 0%);
  background-position: 0 1.15em;
  background-repeat: repeat-x;
  background-size: 4px 2px;
}

.move_cursor {
  cursor: move;
}

.drag_div {
  cursor: move;
  position: absolute;
}

.non_selectable {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.play_pause_button, .restart_button, .undo_button {
  width: 44px;
  height: 44px;
  cursor: pointer;
  touch-action: manipulation;
  background-color: #0000000d;
  background-image: url("play_pause.8ad8d72c.png");
  background-position: -44px 0;
  background-repeat: no-repeat;
  background-size: 132px 44px;
  border-radius: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.undo_button {
  background-position: 0 0;
  background-size: 44px 44px;
}

.play_pause_button.white, .restart_button.white {
  background-color: #ffffff0d;
}

.play_pause_button:hover, .restart_button:hover, .undo_button:hover {
  opacity: .8;
}

.restart_button {
  background-position: -88px 0;
}

.play_pause_button.playing {
  background-position: 0 0;
}

.segmented_control_container {
  height: 40px;
  background: #ddd;
  border-radius: 10px;
  margin-bottom: 2em;
  position: relative;
}

.segmented_control_on, .segmented_control_off {
  height: 36px;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
  font-size: 20px;
  font-weight: 500;
  line-height: 36px;
  position: absolute;
}

.segmented_control_on {
  background: #f8f8f8;
}

.segmented_control_off:hover {
  background: #e8e8e8;
}

.dark .segmented_control_container {
  background: #111;
}

.dark .segmented_control_on {
  background: #272727;
}

.dark .segmented_control_off:hover {
  background: #2c2c2c;
}

@media (pointer: coarse) {
  .click_word {
    display: none;
  }

  .tap_word {
    display: initial;
  }
}

@media (hover: none) {
  .hanchor {
    display: none;
  }
}

.pagination {
  padding: 2em 0;
  text-decoration: none;
  position: relative;
}

.pagination a, .pagination a:visited {
  color: #888;
  padding: 0 1em;
  text-decoration: none;
}

.pagination a:hover {
  color: #aaa;
  text-decoration: none;
}

.pagination .older_entries {
  position: absolute;
  left: 0;
}

.pagination .newer_entries {
  position: absolute;
  right: 0;
}

.canvas_container {
  position: absolute;
  inset: 0;
}

.medium_drawer {
  max-width: 600px;
}

.small_drawer {
  max-width: 400px;
}

.square_drawer:after {
  padding-top: 100%;
}

@media only screen and (max-width: 760px) {
  #site_title {
    font-size: 2.5em;
  }
}

@media only screen and (max-width: 450px) {
  #banner_wrapper, .bg_content {
    padding: 0 1.2em;
  }

  p {
    font-size: 1.1em;
    line-height: 1.6em;
  }

  blockquote {
    margin-left: 1em;
  }

  .segmented_control_on, .segmented_control_off {
    font-size: 18px;
  }
}

@media only screen and (max-width: 350px) {
  p {
    font-size: 1em;
    line-height: 1.6em;
  }

  #navigation a {
    padding-right: 1em;
  }

  #banner_wrapper, .bg_content {
    padding: 0 1em;
  }

  .segmented_control_on, .segmented_control_off {
    font-size: 16px;
  }
}

@media only screen and (max-width: 520px) {
  .slider_container, .long_slider .slider_container {
    max-width: 320px;
  }
}

@media only screen and (max-width: 420px) {
  .slider_container, .long_slider .slider_container {
    max-width: 300px;
  }
}

@media only screen and (max-width: 360px) {
  .slider_container, .long_slider .slider_container {
    max-width: 260px;
  }
}

.pull-right {
  float: right;
  margin-left: 16px;
}

button {
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  min-height: 3rem;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  background-color: #0c8b50;
  background-clip: padding-box;
  border: 1px solid #0000;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  text-decoration: none;
  transition: all .25s;
  display: inline-flex;
  position: relative;
  box-shadow: 0 1px 3px #00000005;
}

button:hover, button:focus {
  background-color: #0ea25e;
  box-shadow: 0 4px 12px #0000001a;
}

button:hover {
  transform: translateY(-1px);
}

button:active {
  background-color: #085c35;
  transform: translateY(0);
  box-shadow: 0 2px 4px #0000000f;
}

.mcq-answer {
  --b: 3px;
  --s: .45em;
  --color: #ccc;
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background: conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p) / calc(100% - var(--b)  - 2 * var(--_p)) calc(100% - var(--b)  - 2 * var(--_p));
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  border: 0;
  font-size: 16px;
  transition: all .3s linear, color, background-color;
}

.mcq-answer:hover, .mcq-answer:focus-visible {
  --_p: 0px;
  --color: #3dbf3d;
  outline-color: var(--color);
  outline-offset: .05em;
}

.mcq-answer:active {
  --color: #2a852a;
  background: var(--color);
  color: #fff;
}

.mcq-answer.mistake {
  backface-visibility: hidden;
  perspective: 1000px;
  --_p: var(--s);
  --color: #555;
  outline: none;
  animation: .8s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

.shake {
  backface-visibility: hidden;
  perspective: 1000px;
  animation: .8s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(8px, 0, 0);
  }
}

html, body {
  color: #eee;
  background: #242222;
}

main {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
}

.question {
  flex-direction: column;
  font-family: Helvetica Neue, Arial, sans-serif;
  display: flex;
  position: relative;
}

.question .q-title {
  background: #343333;
  margin: 0;
  padding: 1rem 0 1rem 3rem;
}

.question .q-title:before {
  content: url("q.9353ac06.svg");
  width: 2rem;
  position: absolute;
  top: 14px;
  left: 12px;
}

.question .q-instruction {
  color: #333;
  text-align: justify;
  background: #fff;
  padding: 0 1.5rem 2rem;
  font-size: 18px;
}

.question .q-instruction .audio {
  width: 42px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px;
  transition: all .15s;
  position: absolute;
  top: 74px;
  right: 1.5rem;
}

.question .q-instruction .audio:hover {
  width: 48px;
  background: #0c506b40;
}

.question .q-instruction .audio:active {
  background: #0c506b80;
}

.question .q-instruction h3 {
  color: #0c506b;
}

.question .q-instruction strong {
  color: #301f71;
}

.question .q-answers {
  background: #343333;
  margin: 0;
  padding: 0;
}

.question .q-answers ul {
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 1rem;
  font-size: 20px;
  display: flex;
}

.question .q-answers ul li {
  list-style-type: none;
}

.question .q-answers .qroc-answer {
  padding: 1rem 1rem .2rem;
}

.question .q-answers .qroc-answer input {
  margin: 0 12px;
}

.question .q-footer {
  background: #343333;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.question .drawer {
  width: 100%;
  height: 380px;
  position: relative;
}

/*# sourceMappingURL=index.cc438d92.css.map */
