html {
    height: 100%;
    width: 100%;
}
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #F8F8F8;
  color: #444;
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif !important;
    -webkit-text-size-adjust: 100%;
}

.segmented_container {
    max-width: 500px;
    margin: 0 auto;
}

.slider_container {
    margin: 0 auto;
    padding: 25px 0 25px 0;
    width: 90%;
    max-width: 380px;
}

.long_slider .slider_container {
    max-width: 600px;
}

.slider_left_gutter,
.slider_right_gutter,
.slider_knob {
    background: #777;
}

.slider_left_gutter,
.slider_right_gutter {
    position: absolute;
    height: 4px;
    top: 18px;
    border-radius: 2px;
}

.slider_knob {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    cursor: ew-resize;
    touch-action: none;
}

.slider_left_gutter,
.slider_right_gutter {
    top: -2px;
}

.slider_right_gutter {
    opacity: 0.2;
}

.slider_knob {
    left: -20px;
    top: -20px;
}

.post_title {
    font-size: 2.4em;
    padding-top: 0.4em;
}

.post_title a {
    color: #535353;
    text-decoration: none;
    font-weight: 700;
}

.post_title a:visited {
    color: #535353;
}

.post_title a:hover {
   color: #777;
}

.sqrt {
    position: relative;
    margin-left: 12px;
}

.sqrt:after {
    position: absolute;
    content: '';
    width: 25px;
    left: -12px;
    top: 0px;
    height: 23px;
/*    background-image: url('../images/sqrt.svg');*/
}

.drawer_container {
    width: 75%;
    height: auto;
    position: relative;
    margin: 2.3em auto 2em auto;
    padding: 0;
    user-select: none;
}

.drawer_container:after {
    padding-top: 66%;
    display: block;
    content: '';
}

.full_width {
    max-width: 100%;
}

.ratio_20:after {
    padding-top: 20%;
}

.ratio_25:after {
    padding-top: 25%;
}

.ratio_30:after {
    padding-top: 30%;
}

.ratio_40:after {
    padding-top: 40%;
}

.ratio_50:after {
    padding-top: 50%;
}

.ratio_60:after {
    padding-top: 60%;
}

.ratio_70:after {
    padding-top: 70%;
}

.ratio_80:after {
    padding-top: 80%;
}

.ratio_90:after {
    padding-top: 90%;
}

.ratio_100:after {
    padding-top: 100%;
}

.ratio_110:after {
    padding-top: 110%;
}

.ratio_120:after {
    padding-top: 120%;
}

.ratio_130:after {
    padding-top: 130%;
}

.hidden {
    display: none !important;
}

.nobreak {
    white-space: nowrap;
}

.click_word {
    display: initial;
}

.tap_word {
    display: none;
}

.rounded_bg {
    position: relative;
    padding: -0.2em 0.3em;
    border-radius: 0.2em;
    background: #eee;
    margin: 0.0em 0.05em;
}

.dotted {
    background-image: linear-gradient(to right, rgba(68, 68, 68, 1) 50%, rgba(68, 68, 68, 0) 0%);
    background-position: 0px 1.15em;
    background-size: 4px 2px;
    background-repeat: repeat-x;
}

.move_cursor {
    cursor: move;
}

.drag_div {
    position: absolute;
    cursor: move;
}

.non_selectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.play_pause_button,
.restart_button,
.undo_button {
    position: absolute;
    width: 44px;
    height: 44px;
    bottom: 0px;
    left: 0px;
    background-image: url('../img/play_pause.png');
    background-repeat: no-repeat;
    background-size: 132px 44px;
    background-position: -44px 0px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    touch-action: manipulation;
}

.undo_button {
/*    background-image: url('/images/undo.png');*/
    background-size: 44px 44px;
    background-position: 0px 0px;
}

.play_pause_button.white,
.restart_button.white {
/*    background-image: url('/images/play_pause_white.png');*/
    background-color: rgba(255, 255, 255, 0.05);
}

.play_pause_button:hover,
.restart_button:hover,
.undo_button:hover {
    opacity: 0.8;
}

.restart_button {
    background-position: -88px 0px;
}

.play_pause_button.playing {
    background-position: 0px 0px;
}

.segmented_control_container {
    background: #ddd;
    height: 40px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 2em;
}

.segmented_control_on,
.segmented_control_off {
    position: absolute;
    text-align: center;
    border-radius: 7px;
    height: 36px;
    cursor: pointer;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    font-weight: 500;
}

.segmented_control_on {
    background: #F8F8F8;
}

.segmented_control_off:hover {
    background: #e8e8e8;
}

.dark .segmented_control_container {
    background: #111;
}

.dark .segmented_control_on {
    background: #272727;
}

.dark .segmented_control_off:hover {
    background: #2c2c2c;
}

@media (pointer:coarse) {
    .click_word {
        display: none;
    }
    .tap_word {
        display: initial;
    }
}

@media (hover: none) {
    .hanchor {
        display: none;
    }
}

.pagination {
    position: relative;
    padding: 2em 0;
    text-decoration: none;
}

.pagination a,
.pagination a:visited {
    padding: 0 1em;
    color: #888;
    text-decoration: none;
}

.pagination a:hover {
    color: #aaa;
    text-decoration: none;
}

.pagination .older_entries {
    position: absolute;
    left: 0;
}

.pagination .newer_entries {
    position: absolute;
    right: 0;
}

.canvas_container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.medium_drawer {
    max-width: 600px;
}

.small_drawer {
    max-width: 400px;
}

.square_drawer:after {
    padding-top: 100%;
}
@media only screen and (max-width: 760px) {
    #site_title {
        font-size: 2.5em;
    }
}

@media only screen and (max-width: 450px) {
    #banner_wrapper,
    .bg_content {
        padding: 0 1.2em;
    }
    p {
        font-size: 1.1em;
        line-height: 1.6em;
    }
    blockquote {
        margin-left: 1.0em;
    }
    .segmented_control_on,
    .segmented_control_off {
        font-size: 18px;
    }
}

@media only screen and (max-width: 350px) {
    p {
        font-size: 1.0em;
        line-height: 1.6em;
    }
    #navigation a {
        padding-right: 1em;
    }
    #banner_wrapper,
    .bg_content {
        padding: 0 1em;
    }
    .segmented_control_on,
    .segmented_control_off {
        font-size: 16px;
    }
}

@media only screen and (max-width: 520px) {
    .slider_container {
        max-width: 320px;
    }
    .long_slider .slider_container {
        max-width: 320px;
    }
}

@media only screen and (max-width: 420px) {
    .slider_container {
        max-width: 300px;
    }
    .long_slider .slider_container {
        max-width: 300px;
    }
}

@media only screen and (max-width: 360px) {
    .slider_container {
        max-width: 260px;
    }
    .long_slider .slider_container {
        max-width: 260px;
    }
}
